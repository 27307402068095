<template>
	<div>
		<div v-if="!edit">
			<TextField v-if="new_val.length > 10" v-model="search" :placeholder="$t('global.action.search')" :appendIcon="search === '' ? '':'mdi-close'" :iconClick="() => {search = ''; display_val = all_vals}" class="mb-2" />
			<v-virtual-scroll
					v-show="display_val.length > 0"
					:items="display_val"
					:height="display_val.length < 10 ? display_val.length * line_height : 10 * line_height"
					:item-height="line_height"
					class="rounded-lg"
			>
				<template v-slot:default="{ item, index }">
					<v-row no-gutters :class="index % 2 === 0 ? 'background':'cell3'" :style="{height: line_height + 'px'}" align="center">
						<v-col class="pa-1">{{ item.display_name }}</v-col>
						<v-col cols="auto" v-if="item.__url__">
							<Button small icon iconVal="mdi-download" :href="$store.getters['config/backend_url'] + item.__url__" />
						</v-col>
					</v-row>
				</template>
			</v-virtual-scroll>
			<div v-show="display_val.length === 0" class="paragraph">{{ $tc('single_trad.Table.no_data', 1) }}</div>
		</div>

		<div v-else>
			<Select v-model="new_val" :items="choices.map(e => {return {display_name: e.display_name, id: e.id}} )" multiple :attach="false" />
		</div>
	</div>
</template>

<script>
	export default {
		name: "MultiSelect",
		props: ['value', 'choices', 'edit', 'label', 'noMenuIcon', 'appendIcon', 'clearable', 'attach', 'fieldName'],
		components: {
			Select: () => import('@/components/fields/Select.vue'),
			TextField: () => import('@/components/fields/TextField.vue'),
			Button: () => import('@/components/ui/Button.vue')
		},
		data() {
			return {
				search: '',
				all_vals: this.value,
				display_val: this.value,
				line_height: 30
			}
		},
		computed: {
			new_val: {
				get() {
					return this.value || []
				},
				set(val) {
					this.display_val = val
					this.all_vals = val
					this.$emit('input', val)
				}
			}
		},
		watch: {
			search(val){
				this.display_val = this.all_vals.filter(e => e.display_name.toLowerCase().match(val.toLowerCase()))
			}
		}
	}
</script>

<style scoped>

</style>
